<template>
    <div class="block w-full relative" style="top: -45px;">
        <summary-skeleton v-if="!show" />
        <div class="block" v-else>
            <div class="flex w-full justify-center">
                <div style="width: 100px; height: 100px;" class="overflow-hidden rounded-lg">
                    <img :src="data.Logo" class="object-cover w-full h-full" alt="">
                </div>
            </div>
            <div class="block w-full text-center mt-3">
                <span class="font-semibold block mb-2">{{ data.Name }}</span>
                <p class="text-gray-500">{{ data.Address }}</p>
            </div>
            <div class="block w-full border boder-solid border-t-0 border-r-0 border-l-0"></div>
            <div class="block w-full m-0">
                <div v-if="Object.keys(schedule).length > 0" class="flex pb-2 w-full justify-between">
                    <span class="w-3/4">{{ schedule.Name }} {{ schedule.Date ? '( ' + schedule.Date.substring(0,10) + ' ' + formatDate(schedule.Date, 'HH:mm') + ' )' : '' }}</span>
                    <span class="font-bold">{{ schedule.CurrencyCode }} {{ schedule.AmountSelected === '' ? formatNumber(schedule.Amount) : formatNumber(schedule.AmountSelected / schedule.Quantity) }}</span>
                </div>
                <div v-if="!schedule.Name">
                    <div v-for="(item, n) in data.Orders" :key="n" class="flex pb-2 w-full justify-between">
                        <span>{{ item.Name }}</span>
                        <span>{{ item.CurrencyCode }} {{ formatNumber(item.Amount) }}</span>
                    </div>
                </div>
                <div v-for="(item, n) in addon" :key="'addon' + n" class="flex pb-2 w-full justify-between">
                    <span>{{ item.Name }}</span>
                    <span>{{ item.CurrencyCode }} {{ formatNumber(item.Amount) }}</span>
                </div>
            </div>
            <div class="block w-full border boder-solid border-t-0 border-r-0 border-l-0"></div>
            <div class="flex font-bold justify-between">
                <span>Quantity</span>
                <span>x{{ schedule.Quantity }}</span>
            </div>
            <div class="font-bold flex justify-between">
                <span>Total</span>
                <span>{{ data.Orders[0].CurrencyCode }} {{ schedule.AmountSelected === '' || typeof schedule.AmountSelected === 'undefined' ? formatNumber(data.Total) : formatNumber(schedule.AmountSelected) }}</span>
            </div>
        </div>
        <form id="summary">
            <input v-if="config.Company === 'ELITE CENTER'" type="hidden" name="Details" :value="JSON.stringify([{...schedule}])">
            <input v-else type="hidden" name="Details" :value="JSON.stringify([{...schedule}, ...addon])">
        </form>
    </div>
</template>
<script>
export default {
    components: {
        SummarySkeleton: () => import('@/components/skeleton/SummarySkeleton')
    },
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            show: false,
            config: JSON.parse(localStorage.getItem('config'))
        }
    },
    computed: {
        addon () {
            var temp
            var element = document.getElementById('AddOn')
            if (element && element.__vue__) temp = element.__vue__.value
            else temp = {}
            return temp
        },
        schedule () {
            var temp = {}
            var element = document.getElementById('schedule')
            if (element && element.__vue__) temp = { ...element.__vue__.obj, Date: element.__vue__.selectedSchedule, AmountSelected: element.__vue__.AmountSelected }
            else temp = {}
            return temp
        }
    },
    methods: {
        init () {
            setTimeout(() => {
                this.show = true
            }, 500)
        }
    },
    mounted () {
        this.init()
    }
}
</script>
